.a {
    height: 100vh;
    display: flex;
    align-items: center;
    background-color: #21325e;
  }
  
  .a-left {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100%;
  }
  
  .a-right {
    flex: 1;
  }
  
  .a-card.bg1 {
    position: absolute;
    top: 50px;
    left: 50px;
    background-color: rgb(255, 125, 25);
  }

  .a-card.bg2{
    position: absolute;
    top: 85px;
    left: 85px;
    background-color: rgb(31, 223, 244);
  }
  
  .a-card {
    width: 60%;
    height: 70vh;
    border-radius: 30px;
    position: relative;
    overflow: hidden;
  }
  
  .a-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .a-award-img {
    width: 120px;
    height: 120px;
    border-radius: 20px;
  }
  
  .a-title {
    font-weight: 400;
    color: #e2ca3e;
  
  }
  
  .a-sub {
    margin: 20px 0px;
    color: #ffffff;
    font-size: 20px;
  }
  
  .a-desc{
      font-weight: 300;
      color: #ffffff;
      margin-right: 10px;
      font-size: 20px;
  }
  
  @media screen and (max-width:480px) {
      .a{
        flex-direction: column;
        text-align: center;
        /* margin-top: 50px; */
      }
  
      .a-left {
        width: 100%;
      }
  
      .a-card {
        height: 30vh;
      }
  
      .a-card.bg1, .a-card.bg2 {
        display: none;
      }
  
      .a-right{
        padding: 20px;
      }

      .a-desc, .a-sub{
        font-size: 15px;
      }
  }