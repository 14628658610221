.project-experience {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #F0FFFF;
  }

  .vertical-timeline-element-p .date-style{
    /* font-size: 340%; */
    /* color: green; */
    font-family: Courier;
    /* font-size: 150%; */
   }

   .project-experience .project-exp-pics{
    height: 100%;
    width: 100%;
    object-fit: cover;
   }